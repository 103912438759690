import React from "react"
import RegistrationAndLoginForm from "../../components/RegistrationComponent/RegistrationAndLoginForm"

const UserRegistration = () =>{
    return(
        <>
        <RegistrationAndLoginForm/>
        </>
    )
}
export default UserRegistration;