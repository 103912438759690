import React from "react";
import ContactForm from "../../components/ContactComponent/ContactForm";

const Contact = () => {
  return (
    <>
      <ContactForm/>
    </>
  );
};
export default Contact;
