import React from "react"
import FAQComponent from "../../components/FAQComponent/FAQComponent"

const FAQ = ()=>{
    return(
        <>
        <FAQComponent/>
        </>
    )
}
export default FAQ;