import React from "react";
// import placeholder from "../../images/profileImg.png"

const PicturesWall = () =>{
    return(
        <>
        <div className="pictures__wall__wrapper">
            <h1>Feeds</h1>
            {/* <div className="pictures__wall__posts">
                <div className="picture__post__wrapper">
                    <div className="pictures__post__profile">
                        <div className="friend__profile" style={{backgroundImage:`url(${placeholder})`}}></div>
                        <h3>Developer#01</h3>
                    </div>
                    <div className="picture__post">

                    </div>
                </div>
            </div> */}
        </div>
        </>
    )
}
export default PicturesWall;